.App {
  text-align: center;
}
.defaultBackgoundOVer{
  
}
.mainimagepdp{
  height:100px;
  border-radius: 15px;
  margin-right: 5px;

}
.mainimagepdp:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.imageBackground{
  background: blue;
}
.panal:hover {
  background: #FFC02C !important;
}
.MuiCardContent-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.MuiDrawer-root{
  background:transparent;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bannerText{
  margin-right: 150px;
}
.textC{
  text-align: center;
}
.defaultTitle{
  font-size:large;
  font-weight:bold;
  margin:20px;
  padding-top:50px;
  text-align: center;
}
.defaultTitle2{
  font-size:large;
  font-weight:bold;
}
/* fb defaults
*/
.fb{
  display:flex;
}
.fdc{
  flex-direction: column;
}
.ml20{
  margin-left: 20px;
}
.fw{
  flex-wrap: wrap;
}
.fdc{
  flex-direction: column;
}
.jr{
  justify-content: right;
}
.jc{
  justify-content: center;
}
.jsb{
  justify-content: space-between;
}
.ma{
  margin:auto;
}
.je{
  justify-content: space-evenly;
}
.sa{
  justify-content: space-around;
}
.ac{
  align-items: center;
}
.fg{
  flex-grow: 1;
}
/* end fb defaults*/
.tac{
  text-align: center;
}
.hmarg40{
  margin: 0px 20px;
}
.dmarg{
  margin:8px;
}
.minH{
  min-height: 400px;
  margin-top: 50px;
}
.minH2{
  min-height: 400px;
  margin-top: 150px;
}
.underlineTitle{
  text-decoration: underline;
  font-size: large;
}
.space_around{
  justify-content: space-around;
}
.cardcontain{
  flex-basis: 250px;
  flex-grow: 1;
  margin: 0px 30px 30px 30px;
  padding: 10px;
  border: 3px solid grey;
  border-radius: 5px;
  box-shadow: 5px 5px lightgrey;
  max-width: 350px;
}
.popShadow{
  padding: 10px;
  border: 3px solid grey;
  border-radius: 5px;
  box-shadow: 5px 5px lightgrey;
}
.shipcardcontain{
  flex-basis: 350px;
  flex-grow: 1;
  padding:20px;
}
.subMenu{
  padding-left: 10px;
}
.successfully-saved {
  color: #FFFFFF;
  text-align: center;

  transition: opacity 3s ease-in-out;
  opacity: 1;
}
.redback{
  background: black;
  color:red;
  font-style: italic;
  text-align: center;
  width: 100%;
  margin: 0px !important;
}
.platBack{
  background: #d7cede;
}
.dropdownmenu{
  padding: 25px;
  border-radius: 0px 15px 15px;
  border: 1px solid grey;
  margin-top: 10px;
}
.logindropdown{
  border: 1px solid grey;
  padding: 8px 13px;
  z-index: 2000;
  position:fixed;
  top:68px;
  text-wrap: nowrap;
}
.breadcrumbcont{
  display: flex;
    flex-wrap: wrap;
}
.cardcontain2{
  flex-basis: 350px;
  flex-grow: 1;
}

.linkbold{
  font-weight: bolder;
}
.horMarg20{
  margin:0 20px;
}
.w100{
  width: 100%;
}
.mainpageblock{
  width: 100%;
  margin:20px auto;
  max-width: 600px;
}

/*helppage*/
.helpAccRoot{
  width: 100%;
  background:transparent;
  margin:20px auto;
  border-radius:12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  max-width: 800px;
}
.helpAccRootRoot{
  background:transparent;

}
.helpAccHead{
  font-size: large;
  font-weight: bold;
}
.helpAccSum{
  background: black;
  border-radius: 15px;
}
.helpAcclb{
  white-space: 'pre-line'
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiTabs-indicator{
  background: transparent !important;
}
.mobiledraw{
  position: fixed;
  top: 90px;
  right: 0;
  background: white;
  border-radius: 10px;
  z-index: 3;
}
.mobileIconLeft{
  position: fixed;
  top: 85px;
  left: 0;
  z-index: 3;
  background: transparent;
}
.MuiCircularProgress-svg{
  color:#FFC02C !important
}
.imageLinkBack{
  background: transparent;
}
.mobiledrawicon{
  font-size: 50px;
  border-radius: 10px;
}
.mobiledrawerexit{
  float: right;
  padding: 20px 20px 0px 0px;
  font-weight: bold;
  font-size: large;
  margin-bottom: -5px;
}
/*mobile*/
@media only screen and (max-width: 600px) {
  .mobileWrap{
    flex-wrap: wrap;
  }
  .headerSpot{
    display: block;
  }
  .cardcontain{
    margin:auto auto 40px auto !important;
  }
  .ppdImageContainer{
    width:100%;
  }
  .breadCrumbContainer{
    font-size: 16;
    margin: 0px 0px 0px 10px;
    text-decoration: underline;
  }
  .breadCrumbSpacing{
    margin-right: 5px;
  }
  .catImageTopContainer{
    display: flex;
    flex-wrap: wrap;
    margin:10px 5px 0px 5px;
  }
  .catImageContainer{
    width:50% !important;
    text-align: center;
    border: 1px solid grey;
    border-radius: 23px;
    padding: 10px 10px 0px 10px;
  }
  .catImageImage{
    background-size: contain !important;
    width:50px !important;
    height:50px !important;
    margin: auto;

  }
  .pdptopcardcontain{
    margin-top: 30px;
    flex-basis: 45%;
    flex-grow: 1;
    text-align: center;
  }
  .privacyMarg{
    margin:40px
  }
  .mobileTextCenter{
    text-align: center;
  }
  .searchResCont{
    background:transparent;
    color:black;
    margin:auto;
  }
  td{
    padding:0px 5px !important;
  }
  .aboutDetials{
    text-align: center;
    min-width: 260px;
    flex: 1 1;
    margin: 30px 10px 30px 10px;
  }
  .aboutImage{
    width: 100%;
    height: auto;
  }
  .h50Box{
    min-width: 200px;
  }
  .mb60{
    margin-bottom: 60px;
  }
  .box_75{
    width: 100%;
  }
  .pricetable{
    overflow-x: scroll;
  }
  .pdpaddspacing{
    margin-bottom: 25px;
  }
  .qtycol{
    width:200px;
    text-wrap: nowrap;
  }
  .catTitleCont{
    text-align: center;
    margin-top: 25px;
  }
  .catCarCont{
    display: flex;
    flex-wrap: wrap;
    margin-left: 50px;
  }
  .insuredshipcont{
    margin:20px;
  }

  .headerTitle1{
    font-family: cursive;
    font-size: 3.8rem;
    font-size: 38px;
    font-size: 2.375rem;
    text-shadow: 3px 3px gray;
  }
  .background-container {
    background-size: cover; /* Adjust to your needs */
    background-position: center; /* Adjust to your needs */
    /* Additional styles if needed */
  }
  .headerTitle2{
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: uppercase;
    font-family: cursive;
    font-family: cursive;
    }
  .headerTitle3{
    font-size: 1.625rem;
    font-weight: 200;
    text-transform: uppercase;
    line-height: 1.4;
    text-shadow: 3px 3px gray;
  }
  .spotCont{
    width:100%;
    justify-content: space-between;
  }
  .homeHeaderBackgourd{
    width: 100%;
    background-repeat: no-repeat;
    color:black !important;
    margin:auto;
}
.crcholder{
  text-align: center;
  height: auto;
  background: transparent;
  position: relative;
  padding-top: 115px;
}
.homePageCustImag{
  height: 160px;
  width: 100%;
  max-width: 1000px;
  margin: 40px auto;
  display: flex;
  justify-content: center;
}
}
/*Desktop*/
@media only screen and (min-width: 700px) {
  .recommendCardContain{
    max-width: 20%;
  }
  .recommendCont{
    margin: 0 5%;
    border: 3px solid silver;
    border-radius: 15px;
    box-shadow: 5px 5px lightgrey;
    padding-top: 18px;
  }
  .privacyMarg{
    margin:100px
  }
  .breadCrumbContainer{
    font-size: 25px;
    margin: 15px 10px 20px 50px;
    text-decoration: underline;
  }
  .catImageTopContainer{
    display: flex;
    flex-wrap: wrap;
    margin-left: 50px;
  }
  .catImageContainer{
    margin-right: 30px;
  }
  .breadCrumbSpacing{
    margin-right: 30px;
  }
  .catImageImage{
    background-size: contain !important;
    width:100%;
    border-radius: 15px 15px 0px 0px;
  }
  .ppdImageContainer{
    width:auto;
    height:330px;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  .pdptopcardcontain{
    margin-top: 30px;
    flex-basis: 45%;
    flex-grow: 1;
    padding:40px 20px;
    text-align: center;
  }
  .aboutDetials{
    margin: 30px;
  }
  .desktopIconCartRow{
    margin-top: 35px;
  }
  .homePageCustImag{
    height: 230px;
    width: 100%;
    max-width: 1000px;
    margin: 70px auto;
    display: flex;
    justify-content: center;
  }
  .searchResCont{
    margin-left: 50px;
    background:transparent;
    margin-top: 15px;
    color:black
  }
  .homePageCustImagCont{
    display: flex;
    justify-content: center;
  }
  .crcholder{
    text-align: center;
    height: auto;
    background: transparent;
    position: relative;
    padding-top: 250px;
  }
  .mobilefb{
    display: flex;
  }
  .spotCont{
    margin-left: 20px;
    width: 60%;
  }
  .h50Box{
    width:45%;
    margin-left:30px;
    margin-top:20px;
    min-width: 200px;
  }
  .defPageMarg{
  }
  .catTitleCont{
    text-align: center;
    margin-top: 25px;
  }
  .catCarCont{
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
  }
  .insuredshipcont{
    margin-left:20px;
    text-wrap: nowrap;
    font-size: 12px;
  }
  .headerTitle1{
    font-family: cursive;
    font-size: 3.8rem;
    font-size: 38px;
    font-size: 2.375rem;
    font-weight: 700;

}
.headerTitle2{
  font-size: 6.25rem;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 5px 5px gray;
  font-family: cursive;
  padding-bottom: 20px;
}
.headerTitle3{
  font-size: 1.625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
  color:white;
  font-size: xx-large;
}
.toptri {
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 15px;
  border-radius: 0px 300px 0px 0px;
  border-left: 1px solid grey;
}

.goldtop{
  width: 65px;
}
.silvertop{
  width: 70px;
}
.plattop{
  width:90px;
}

.fade-up {
  opacity: 0;
  transform: translateY(0px);
  animation: fadeUp 5s forwards;
}
@keyframes fadeUp {
  from {
    opacity: .7;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.homeHeaderBackgourd{
    width: 100%;
    background-repeat: no-repeat;
    color:black !important;
    margin:auto;
}
.greyHor{
  background: rgb(166,160,155);
  height: 50px;
}
.headHeight{
  height:120px;
}
.headerPaddTop{
  padding:30px 80px;
}
}
/*Desktop large*/
@media only screen and (min-width: 800px) {
  .box_75{
    width: 75%;
    margin-right: 10px;
  }
}

.fimage{
  border: 10px solid red;
    height: 150px;
}
.mediumTitle{
  font-size: x-large;
  font-weight: bold;
}
/* cart */
.ctitle{
  font-weight: bold;
  font-size: large;
}
.hoverPointerClick{
  cursor: pointer;
}
.summaryBlock{
  border: 5px solid grey;
  padding:20px;

}
td{
  padding:0px 20px
}
th,td{
  text-align: center;
}
.attrcont{
  max-width: 400px;
}

.col5{
  min-width: 200px;
  flex-grow: 1;
  margin-bottom: 15px;
}
.mbottom{
  margin-bottom: 40px;
}
.vmarg{
  margin: auto 0;
}
.vmarg8{
  margin-top:10px;
}
.vmarg15{
  margin-top:25px;
}

@media (prefers-color-scheme: light) {
  body,div,p,h1,h2,a{
    background: white ;
    color: black ;
  }
  .MuiModal-root{
    background: transparent;
  }
  .defaultBack{
      background: white;
  }
  table, td, th {
    border: 1px solid black !important;
  }
}
@media (prefers-color-scheme: dark) {
  .marqueeback{
    background:black;
  }
  .slick-dots li button:before{
    color: white !important;
  }
  .specTable{
    border:1px solid white;
  }
  .specRow{
    border:1px solid white;
    text-align: left !important;
  }
  .MuiAlert-icon,.MuiAlert-message,.MuiAlert-action{
    background:transparent;
  }
  .alwaysTransParent{
    background: transparent !important;
  }
  body,div,p,h1,h2,a,.darkBackText,section,footer,td,th{
      background: black ;
    color: #FFF ;

  }
  .mobiledraw{
    background-color: gray;
  }
  .MuiSvgIcon-root{
    color:white !important;
  }
  .MuiBottomNavigation-root{
    background-color: gray !important;
  }
  th {
    border-bottom: 1px solid white !important;
  }
  td, th {
    border-right: 1px solid white !important;
  }
  .MuiBottomNavigationAction-label.Mui-selected{
    color:white;
  }
  .MuiOutlinedInput-notchedOutline{
    border:2px solid white !important;
  }
  .MuiInputLabel-root{
    color:white !important;
  }
  .MuiOutlinedInput-input{
    color:white !important;
  }
  .MuiAccordionSummary-root{
    background: black !important;
    border: 1px solid white !important;
    border-radius: 15px !important;
  }
  .MuiCollapse-vertical{
    border: 1px solid white;
  }
  .MuiDrawer-paperAnchorLeft{
    background-color: black !important;
    border: 3px solid white;
  }
  .MuiAlert-standardSuccess{
    background-color: #2e7d32 !important;
  }
  .MuiDialogContentText-root{
    color:white !important;
  }
  .MuiDialog-scrollPaper,.MuiModal-backdrop{
    background-color: transparent !important;
  }
  .MuiPopover-root{
    background: transparent;
  }
  .MuiTab-textColorPrimary{
    color:gray !important;
  }
  .MuiPopper-root{
    background: transparent;
  }
  .MuiList-root{
    background-color: grey;
  }
  .MuiPaper-elevation{
    background: black !important;
  }
}
.dealzone{
  font-style: italic;
  color: red;
  margin-top: 3px !important;
  font-size: large !important;
  margin-left: 10px;
}
.dealzone2{
  font-style: italic;
  color: red;
  margin: 10px 0px !important;
  font-size: large !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  background-color: grey !important;
}
.goldButton{
  background: #FFC02C !important;
  color: black !important;
}
/*menu styles*/
.MuiTabs-scroller{
  overflow: visible !important;
}
.menuitem{
  width: 180px;
  border-bottom: 2px solid lightgray !important;
}
.clearBack{
  background-color: transparent;
  color:black;
}

.fgrey{
  background-color:rgb(166,160,155);
  color: black;
}
.goldBack{
  background-color: #FFC02C !important;
  color:black !important;
  font-weight: bold !important;
}
.mb15{
  margin-bottom: 15px;
}
.MuiTabs-flexContainer{
  background-color: #FFC02C !important;
  color:black;
  font-weight: bold;
  justify-content: space-evenly;
}
.blackIcon{
  color:black !important;
}
.iconpadd{
  padding-top: 8px;
}
.hpboxHeader{
  position: relative;
  background: #000;
  font-size: 2.6rem;
  font-size: 26px;
  font-size: 1.625rem;
  padding: 10px;
  color: #fff;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}
.hpboxbody{
  margin-bottom: 30px;
  padding:20px;
}
.hpboxBox{
  text-align: center;
  min-width: 320px;
  flex: 1;
  margin: 30px;
  border: 3px solid grey;
  border-radius: 5px;
  box-shadow: 5px 5px lightgrey;
}
.hpboxButtons{
  background: #FFC02C !important;
  border-radius: 15px !important;  
  margin-bottom: 20px !important;  
  color:black !important;
}
.headerHold{
  position:fixed;
  left:0;
  right:0;
  z-index: 1000;
}
.fontS{
  font-size: 12px;
}

.smallTextFoot{
  width: 100px;
  font-size: 10px;
}

.linkstyle{
  margin-right: auto;
  text-wrap: nowrap;
}

.dialogMessage{
  padding:16px;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-actions {
  margin-top: auto;
}
.addNotify .MuiAlert-message{
  background-color: #2e7d32;

}
.MuiAlert-filled{
  background-color: #2e7d32 !important;

}
.addNotify .MuiAlert-icon{
  background-color: #2e7d32;

}
#privacy-policy {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 20px;
  z-index: 999;
}

#privacy-policy p {
  margin: 0;
  padding: 0 0 10px 0;
}

#privacy-policy button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#agree-button {
  background-color: #4CAF50;
  color: white;
}

#disagree-button {
  background-color: #f44336;
  color: white;
}

#agree-button:hover {
  background-color: #45a049;
}

#disagree-button:hover {
  background-color: #e53935;
}

.selectedSpect{
  color:black !important;
  font-weight: bolder !important;
}

.privacyTable{
  border:1px solid;
}
.privacyTable td{
  border:1px solid;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}


.imgwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #222831;
  height: 300px;
  width:300px;
  font-family: sans-serif;
  text-align: center;
  color: #fff;
}
.hideBox{
  display: none;
}
.marginOverride{
  margin-top: 10px !important;
}
.redSVG{
  color:red !important;
}
.greenSVG{
  color:green !important;
}